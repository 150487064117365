<template>    
  <div class="background_basic">          
    <v-card class="ma-3">
      <v-toolbar dense flat color="white">      
        <v-toolbar-title class="grey--text text--darken-3">
          <v-icon class="mr-2">mdi-account-network</v-icon>태그매핑
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="blue" text @click="initialize()" class="font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">refresh</v-icon>초기화
        </v-btn>
        <v-btn color="blue" text @click="ExcelExpert('태그매핑')" class = "ml-2 font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">mdi-microsoft-excel</v-icon>엑셀
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="UserInfo.CorpId == 'NONE' || UserInfo.CorpId == 'SKMR'" color="blue" text @click="UploadExcel()" class="font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">mdi-cloud-upload</v-icon>엑셀일괄업로드
        </v-btn>        
      </v-toolbar>
      <v-divider></v-divider>

      <!-- 아래줄에 항목별 검색기능 추가 -->
      <div class="d-flex flex-wrap">         
        <v-card flat class="d-flex ml-3 mr-1">                                                   
          <v-text-field
            v-model="cNowSearchKeyWord"
            class="ml-0 mb-n4"
            label="검색어"
            placeholder="관리번호 또는 이름 또는 업체명 입력"
            persistent-placeholder
            style="max-width:450px; width:400px;"
            Regular
            v-on:keyup.enter="Search()"
          ></v-text-field>
          <!-- 검색버튼 -->
          <v-btn icon class="ml-n8 mt-3 text-subtitle-1" @click="Search()">
            <v-icon>search</v-icon>
          </v-btn>          
        </v-card>        
        
        <v-card flat class="d-flex ml-3 mr-3">                                                     
          <v-text-field
            v-model="cNowSearchKeyWordTag"
            class="ml-0 mb-n4"
            label="검색어"
            placeholder="태그 입력"
            persistent-placeholder
            style="max-width:350px; width:300px;"
            Regular
            v-on:keyup.enter="Search()"
          ></v-text-field>
          <!-- 검색버튼 -->
          <v-btn icon class="ml-n8 mt-3 text-subtitle-1" @click="Search()">
            <v-icon>search</v-icon>
          </v-btn>
        </v-card>                 
      </div>
      <v-divider></v-divider>
      <v-data-table         
        :items-per-page="15" 
        fixed-header 
        height="calc(100vh - 248px)" 
        :headers="headers" 
        :items="Records"
        @dblclick:row="handleDoubleClick">        
        <template v-slot:item.action="{ item }">
          <v-icon  big class="mr-0" @click="editItem(item)" >mdi-card-account-details-star</v-icon>           
          <v-icon v-if="item.cTagId != ''" big @click="deleteItem(item)"  class="ml-2">mdi-card-bulleted-off-outline</v-icon>
          <!-- <v-icon  big class="mr-0" @click="editItem(item)" :disabled="!ProgramPermissionInfo.okU">mdi-card-account-details-star</v-icon>           
          <v-icon v-if="item.cTagId != ''" big @click="deleteItem(item)" :disabled="!ProgramPermissionInfo.okD" class="ml-2">mdi-card-bulleted-off-outline</v-icon> -->
        </template>                
        <template v-slot:item.cDel="{ item }">
          <div v-if="item.cUserId == 'NONE'" color="red darken-4" label small dark></div>
          <v-chip v-else-if="item.cDel == 'Y'" color="red darken-3" label small dark>삭제</v-chip>          
          <v-chip v-else color="grey lighten-4" label small >정상</v-chip>
        </template> 
        <template v-slot:no-data>
          <p>No data available.</p>
        </template>
      </v-data-table>
    </v-card>      
      
    
    <MsgBox ref="MsgBoxRef" @onMsgBox="onMsgBoxFunc" />    
    <TagSelect ref="TagSelectRef" @onSelect="onSelect" />
  </div>
</template>

<script>
import MsgBox from "@/components/MsgBox.vue";
import TagSelect from "@/components/TagSelect.vue";
import BasicInfo from "../BasicInfo.js";
import Util from "../Util.js";
import axios from "axios";
import { mapState } from "vuex";
import XLSX from 'xlsx';
import EventBus from '@/eventBus.js';

 

export default {
  components: {
    MsgBox,  
    TagSelect,
  },
  data: () => ({
    isGroupALL : false,    
    cGroup : "ALL", 
    cNowSearchKeyWord : "",
    cNowSearchKeyWordTag : "",
    loading : false,
    isEditMode : false,
    iDelIndex: 0,
    dialog: false,
    model : 0,
    sDate : "",
    cLev: [],            
    headers: [      
      { class: "font-weight-bold subtitle-2", text: "작업자SEQ", value: "cUserId",  },
      { class: "font-weight-bold subtitle-2", text: "이름", value: "cUserNm", align: "center" },      
      { class: "font-weight-bold subtitle-2", text: "업체명", value: "cCorpNm", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "부서명", value: "cPartNm", align: "center" },      
      { class: "font-weight-bold subtitle-2", text: "삭제", value: "cDel", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "태그", value: "cTagId", align: "center" },            
      { class: "font-weight-bold subtitle-2", text: "유형", value: "cType", align: "center"},      
      { class: "font-weight-bold subtitle-2", text: "작업", value: "action", align: "center", sortable: false},
    ],
    Records: [],
    editedIndex: -1,    
    editedItem: { 
      cUserId: "NONE",
      cUserNm: "",
      cPwd: "",
      cInfo: "",
      cLev: "",
      cCorpId: "NONE",
      cCorpNm: "",      
      cPartNm: "",
      cIsEmployed: "",
      cUnregDate: null,
      cUserRoleId: "",
      cMapTreeIdx: "",
      cEmail: "",
      cBirth: "",
      cPwdChangeDate: "",
      cPwdExpireDate: "",
      cPwdFailCnt: 0,
      cLocked: "",
      cUse: "",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    },
    deletedItem: {
      cUserId: "NONE",
      cUserNm: "",
      cPwd: "",
      cInfo: "",
      cLev: "",
      cCorpId: "NONE",
      cCorpNm: "",      
      cPorpNm: "",
      cIsEmployed: "",
      cUnregDate: null,
      cUserRoleId: "",
      cMapTreeIdx: "",
      cEmail: "",
      cBirth: "",
      cPwdChangeDate: "",
      cPwdExpireDate: "",
      cPwdFailCnt: 0,
      cLocked: "",
      cUse: "",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    },
    defaultItem: {
      cUserId: "NONE",
      cUserNm: "",
      cPwd: "",
      cInfo: "",
      cLev: "",
      cCorpId: "NONE",
      cCorpNm: "",      
      cPorpNm: "",
      cIsEmployed: "",
      cUnregDate: null,
      cUserRoleId: "",
      cMapTreeIdx: "",
      cEmail: "",
      cBirth: "",
      cPwdChangeDate: "",
      cPwdExpireDate: "",
      cPwdFailCnt: 0,
      cLocked: "",
      cUse: "",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    }
  }),

  computed: {
    ...mapState(["UserInfo", "ProgramPermissionInfo", "zGroupAll"]),
  },

  created() {
    if (this.$store.state.UserInfo.isGroupMode && this.$store.state.UserInfo.GroupId == "ALL") {
      this.isGroupALL = true;
      this.$store.state.GetGroupInfo(); 
    }

    this.initialize();
  },

  methods: {    
    initialize() {
      this.cNowSearchKeyWord = "";
      this.cNowSearchKeyWordTag = "";

      if (this.$store.state.UserInfo.isGroupMode) {
        this.cGroup = this.$store.state.UserInfo.GroupId;  
      }

      this.Search();
      this.model = 0;
    },


    onSelect(sUserId, cOldTagId, cTagId, cType){
      console.log(sUserId, cOldTagId, cTagId, cType);    

      var posData = {
        cUpdateUserId: this.$store.state.UserInfo.UserId,
        cUserId: this.editedItem.cUserId,                
        cOldTagId: this.editedItem.cTagId,
        cTagId: cTagId,
      }

      this.loading = true;
      axios
        .post(BasicInfo.UIL_API + "SetTagMapping", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd === "00") {
            if (this.isEditMode) {
              this.editedItem.cTagId = cTagId;
              this.editedItem.cType = cType;              
              this.editedItem.cUpdateUserId = res.data.Result[0].cUpdateUserId;
              this.editedItem.cUpdateDateTime = res.data.Result[0].cUpdateDateTime;
              Object.assign(this.Records[this.editedIndex], this.editedItem);
            }
            this.dialog = false;
            EventBus.$emit("onShowMsg",false, "저장되었습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },

    ClearDialog() {      
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;                      
    },

    handleDoubleClick(value, items) {       
      // if (!this.ProgramPermissionInfo.okU) return;
      // this.editItem(items.item);  
      console.log(items);
    },
    
    Search() {
      this.loading = true;
      this.Records = [];
      var posData = {
        cNowSearchKeyWord : this.cNowSearchKeyWord,
        cNowSearchKeyWordTag : this.cNowSearchKeyWordTag,
        cCorpId : this.UserInfo.CorpId,
        cNowSiteId : this.UserInfo.NowSiteId,
      };
      axios
        .post(BasicInfo.UIL_API + "GetTagUser", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {          
          if (res.data.ResultCd === "00") {
            this.Records = res.data.Result;            
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;  
          
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },


    UploadExcel() {
      //
    },

    changeUse(item){      
      //console.log("changeUse", item.cUserId, item.bUse);
      item.cUse = "N"
      if (item.bUse){ item.cUse = "Y" }
      
      var posData = {
        cUserId : this.$store.state.UserInfo.UserId,
        cEditUserId : item.cUserId,
        cUse : item.cUse,
      }
      
      this.loading = true;
      axios
        .post(BasicInfo.UIL_API + "SetUserInfoUse", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {          
          if (res.data.ResultCd === "00") {            
            EventBus.$emit("onShowMsg",false, "저장했습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },
    
    // 신규등록/수정 다이올로그 취소시 닫음 (0.3초가 지나면 디폴트값으로 넣어줌)
    close() {      
      this.dialog = false;
      this.isEditMode = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    // 수정 다이올로그 생성 (테이블상의 연필모양 클릭시, isEditMode는 수정못함)
    editItem(item) {      
      this.isEditMode = true;
      this.editedIndex = this.Records.indexOf(item);   
      this.editedItem = Object.assign({}, item);       

      this.$refs.TagSelectRef.Show(item.cUserId, item.cTagId, item.cGroupId);
    },

    // 삭제알람 다이올로그 띄우기 (클릭시 DB에 등록 진행: onMsgBoxFunc와 연동)
    deleteItem(item) {
      this.isEditMode = true;
      this.editedIndex = this.Records.indexOf(item);  
      this.editedItem = Object.assign({}, item);
      this.$refs.MsgBoxRef.RunMsgBoxShow(
        "확인",
        "삭제하시겠습니까? \r\n 삭제하시면 복원되지 않습니다. \r\n[" +
          this.editedItem.cUserNm + "/" + this.editedItem.cTagId +
          "]"
      );
    },

    onMsgBoxFunc: function(result) {
      if (result === true) {        
        this.onSelect(this.editedItem.cUserId, this.editedItem.cTagId, "", "");
      }
    },
    
    ExcelExpert(name){
      var r = Util.GetExcel(this.Records, this.headers);
      var animalWS = XLSX.utils.json_to_sheet(r)       
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, animalWS, name) // sheetAName is name of Worksheet      
      XLSX.writeFile(wb, name + "-" + Util.sNowDate() + '.xlsx'); // name of the file is 'book.xlsx'
    },
  }
};
</script>
