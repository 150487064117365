<template>    
  <v-dialog v-model="dialog" max-width="600px">
    <v-card style="height:calc(100vh - 200px);" >              
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3">
          태그 선택
          </v-toolbar-title>          
          <v-divider class="mx-4" inset vertical></v-divider>          
          <v-text-field
            v-model="cNowSearchKeyWord"
            class="ml-0 mt-6"  
            label="Search"
            placeholder="검색어를 입력하세요."      
            persistent-placeholder      
            Regular
            ref="NowSearchKeyWordRef"
            v-on:keyup.enter="Search()"
          ></v-text-field>        
          <v-btn  text icon  
          class="ml-n9"                
            @click="Search()"            
          ><v-icon>search</v-icon>
          </v-btn>  
        </v-toolbar>
        <v-divider></v-divider>      
      <v-data-table :items-per-page="-1" fixed-header                 
        :headers="headers" 
        :items="Records" 
        item-key="cTagId"                
        height="calc(100vh - 345px)"
      > 
        <template v-slot:item.action="{ item }">
          <v-icon big class="mr-0" @click="select(item)" >mdi-check</v-icon>                    
        </template>    
        <template v-slot:no-data>
          <p>No data available.</p>
        </template>
      </v-data-table>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>             
        <v-btn color="black" text @click="closePage()"
          ><v-icon class="mr-2">mdi-close-circle</v-icon>닫기</v-btn>
      </v-card-actions>
    </v-card>    
  </v-dialog>  
</template>

<script>
// import Util from "../Util.js";
import axios from "axios";
import BasicInfo from "@/BasicInfo.js";
import EventBus from '@/eventBus.js';


export default {
  components: {    
  },
  data() {
    return {
      dialog: false,
      sUserId : "",
      sOldTagId : "",
      sGroupId : "",
      cNowSearchKeyWord : "",
      headers: [        
        { class: "font-weight-bold subtitle-2",  text: "ID(MAC)", value: "cTagId"},        
        { class: "font-weight-bold subtitle-2",  text: "유형", value: "cType"},        
        { class: "font-weight-bold subtitle-2",  text: "선택", value: "action", sortable: false, align: "center",}
      ],
      Records: [],
    };
  },  
  created() {
    this.dialog = false;
  },
  watch: {
    dialog(val) {
      val || this.closePage();
    }
  },
  mounted () {    
  },
  methods: {    
    closePage: function() {                
      this.dialog = false;
    },
    getColor(cSendFlag) {
      if (cSendFlag === "N") return "#ff4862";
      else return "#70bbfe";
    },    

    getColor2(cSendFlag) {
      if (cSendFlag === "Y") return "#ff4862";
      else return "#70bbfe";
    },   

    Show(sUserId, sOldTagId, sGroupId) {                   
      this.Records = [];      
      this.cNowSearchKeyWord = "";
      this.dialog = true;      
      this.sUserId = sUserId
      this.sOldTagId = sOldTagId
      this.sGroupId = sGroupId

      setTimeout(() => {
        this.$refs.NowSearchKeyWordRef.focus();  
        this.Search();
      }, 300);      
    },
    select(item){
      this.$emit("onSelect", this.sUserId, this.sOldTagId, item.cTagId, item.cType);
      this.dialog = false;   
    },    
    Search(){
      this.Records = [];

      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,
        cNowSearchKeyWord : this.cNowSearchKeyWord,                
        cGroupId : this.sGroupId,                
      };
      axios
        .post(BasicInfo.UIL_API + "GetTagInfoSel", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }          
          
          if (res.data.ResultCd === "00") {
            this.Records = res.data.Result;                   
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);          
        });
    },
  }
};
</script>
